import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

/**
 * dotenv is imported here because without it,
 * running scripts files with tsx fails
 */
import dotenv from "dotenv";
dotenv.config();

const schemaBooleanFromString = z
  .string()
  // only allow "true" or "false"
  .refine((s) => s === "true" || s === "false")
  // transform to boolean
  .transform((s) => s === "true");

export const env = createEnv({
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars.
   */
  server: {
    GOOGLE_CLIENT_ID: z.string(),
    GOOGLE_CLIENT_SECRET: z.string(),
    APP_URL: z.string(),
    MJ_APIKEY_PUBLIC: z.string(),
    MJ_APIKEY_PRIVATE: z.string(),
    POSTGRES_PRISMA_URL: z.string().url(),
    POSTGRES_URL_NON_POOLING: z.string().url(),
    NODE_ENV: z
      .enum(["development", "test", "production"])
      .default("development"),
    NEXTAUTH_SECRET:
      process.env.NODE_ENV === "production"
        ? z.string()
        : z.string().optional(),
    NEXTAUTH_URL: z.preprocess(
      // This makes Vercel deployments not fail if you don't set NEXTAUTH_URL
      // Since NextAuth.js automatically uses the VERCEL_URL if present.
      (str) => process.env.VERCEL_URL ?? str,
      // VERCEL_URL doesn't include `https` so it can't be validated as a URL
      process.env.VERCEL ? z.string() : z.string().url(),
    ),
    CRYPTO_KEY: z.string(),
    SEND_EMAILS: schemaBooleanFromString,
    S3_ACCESS_KEY: z.string(),
    S3_SECRET_KEY: z.string(),
    S3_REGION: z.string(),
    S3_BUCKET_UPLOADS: z.string(),
    S3_BUCKET_IMAGE_PROFILE: z.string(),
    S3_ENDPOINT: z.string().optional(),
    S3_FORCE_PATH_STYLE: schemaBooleanFromString.optional(),
    ATTIO_API_KEY: z.string(),
    INTERCOM_SECRET_KEY: z.string(),
    PAPPERS_API_KEY: z.string(),
    PUSHER_APP_ID: z.string(),
    PUSHER_APP_SECRET: z.string(),
  },

  /**
   * Specify your client-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars. To expose them to the client, prefix them with
   * `NEXT_PUBLIC_`.
   */
  client: {
    NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID: z.string().optional(),
    NEXT_PUBLIC_HIGHLIGHT_ENVIRONMENT: z.string().optional(),
    NEXT_PUBLIC_REACT_APP_GOOGLE: z.string(),
    NEXT_PUBLIC_APP_ENV: z.enum(["local", "preview", "production"]),
    NEXT_PUBLIC_INTERCOM_APP_ID: z.string(),
    NEXT_PUBLIC_GOOGLE_ANALYTICS_ID: z.string(),
    NEXT_PUBLIC_PUSHER_APP_KEY: z.string(),
    NEXT_PUBLIC_PUSHER_CLUSTER: z.string(),
  },

  /**
   * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
   * middlewares) or client-side so we need to destruct manually.
   */
  runtimeEnv: {
    GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID,
    GOOGLE_CLIENT_SECRET: process.env.GOOGLE_CLIENT_SECRET,
    APP_URL: process.env.APP_URL,
    MJ_APIKEY_PUBLIC: process.env.MJ_APIKEY_PUBLIC,
    MJ_APIKEY_PRIVATE: process.env.MJ_APIKEY_PRIVATE,
    POSTGRES_PRISMA_URL: process.env.POSTGRES_PRISMA_URL,
    POSTGRES_URL_NON_POOLING: process.env.POSTGRES_URL_NON_POOLING,
    NODE_ENV: process.env.NODE_ENV,
    NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,
    NEXTAUTH_URL: process.env.NEXTAUTH_URL,
    NEXT_PUBLIC_REACT_APP_GOOGLE: process.env.NEXT_PUBLIC_REACT_APP_GOOGLE,
    NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID:
      process.env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID,
    NEXT_PUBLIC_HIGHLIGHT_ENVIRONMENT:
      process.env.NEXT_PUBLIC_HIGHLIGHT_ENVIRONMENT,
    CRYPTO_KEY: process.env.CRYPTO_KEY,
    SEND_EMAILS: process.env.SEND_EMAILS,
    NEXT_PUBLIC_APP_ENV: process.env.NEXT_PUBLIC_APP_ENV,
    ATTIO_API_KEY: process.env.ATTIO_API_KEY,
    S3_ACCESS_KEY: process.env.S3_ACCESS_KEY,
    S3_SECRET_KEY: process.env.S3_SECRET_KEY,
    S3_REGION: process.env.S3_REGION,
    S3_BUCKET_UPLOADS: process.env.S3_BUCKET_UPLOADS,
    S3_BUCKET_IMAGE_PROFILE: process.env.S3_BUCKET_IMAGE_PROFILE,
    S3_ENDPOINT: process.env.S3_ENDPOINT,
    S3_FORCE_PATH_STYLE: process.env.S3_FORCE_PATH_STYLE,
    NEXT_PUBLIC_INTERCOM_APP_ID: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
    INTERCOM_SECRET_KEY: process.env.INTERCOM_SECRET_KEY,
    NEXT_PUBLIC_GOOGLE_ANALYTICS_ID:
      process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID,
    PAPPERS_API_KEY: process.env.PAPPERS_API_KEY,
    PUSHER_APP_ID: process.env.PUSHER_APP_ID,
    NEXT_PUBLIC_PUSHER_APP_KEY: process.env.NEXT_PUBLIC_PUSHER_APP_KEY,
    PUSHER_APP_SECRET: process.env.PUSHER_APP_SECRET,
    NEXT_PUBLIC_PUSHER_CLUSTER: process.env.NEXT_PUBLIC_PUSHER_CLUSTER,
  },
  /**
   * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation. This is especially
   * useful for Docker builds.
   */
  skipValidation: !!process.env.SKIP_ENV_VALIDATION,
  /**
   * Makes it so that empty strings are treated as undefined. `SOME_VAR: z.string()` and
   * `SOME_VAR=''` will throw an error.
   */
  emptyStringAsUndefined: true,
});
