export enum QuestionInlineSlug {
  AssetLoanLoanType = "asset-loan-loan-type",
  AssetLoanMonthlyPaymentAmount = "asset-loan-monthly-payment-amount",
  AssetLoanAmount = "asset-loan-amount",
  AssetLoanEndDate = "asset-loan-end-date",
  AssetLoanRate = "asset-loan-rate",
  AssetLoanGuarantee = "asset-loan-guarantee",
  AdditionalIncomeType = "income-additional-type",
  AdditionalAnnualIncome = "additional-annual-income",
  AssetJointOwnerFirstname = "asset-joint-owner-firstname",
  AssetJointOwnerLastname = "asset-joint-owner-lastname",
  AssetJointOwnerSharePercentage = "asset-joint-owner-share-percentage",
  AssetCompanyShareholderFirstname = "asset-company-shareholder-firstname",
  AssetCompanyShareholderLastname = "asset-company-shareholder-lastname",
  AssetCompanyShareholderType = "asset-company-shareholder-type",
  AssetCompanyShareholderSharePercentage = "asset-company-shareholder-share-percentage",
  AdditionalLoanType = "additional-loan-type",
  AdditionalLoanPurpose = "additional-loan-purpose",
  AdditionalLoanMonthlyPaymentAmount = "additional-loan-monthly-payment-amount",
  AdditionalLoanAmount = "additional-loan-amount",
  AdditionalLoanEndDate = "additional-loan-end-date",
  AdditionalLoanRate = "additional-loan-rate",
  AdditionalLoanGuarantee = "additional-loan-guarantee",
  InvestmentsType = "investments-type",
  InvestmentsAmount = "investments-amount",
  AssetShareholderType = "AssetShareholderType",
}
export enum QuestionSimpleSlug {
  CasePurpose = "case-purpose",
  CaseType = "case-type",
  CaseTypePatrimonial = "case-type-patrimonial",
  CompanySiret = "company-siret",
  CompanyTaxType = "company-tax-type",
  CompanyAccountValue = "company-account-value",
  AssetAddress = "asset-address",
  AssetSurface = "asset-surface",
  AssetType = "asset-type",
  AssetValue = "asset-value",
  AssetLoans = "asset-loans",
  AssetUsageType = "asset-usage-type",
  AssetRentAnnualAmount = "asset-rent-annual-amount",
  AssetOwnershipType = "asset-ownership-type",
  AssetJointOwnerInfo = "asset-joint-owner-info",
  AssetCompanyType = "asset-company-type",
  AssetCompanyShareholderInfo = "asset-company-shareholder-info",
  AssetAddNew = "asset-add-new",
  FinancingRequestedAmount = "financing-requested-amount",
  FinancingUsageDescription = "financing-usage-description",
  CustomerAge = "customer-age",
  CustomerChildrenCount = "customer-chidren-count",
  CustomerAddress = "customer-address",
  CustomerMaritalStatus = "customer-marital-status",
  CustomerMarriageType = "customer-mariage-type",
  CustomerJobType = "customer-job-type",
  CustomerJobEmployeeType = "customer-job-employee-type",
  CustomerJobTrial = "customer-job-trial",
  CustomerHasEnoughAnnualIncome = "customer-has-enough-annual-income",
  AnnualIncome = "annual-income",
  LastYearAnnualIncome = "last-year-annual-income",
  AnnualPensionIncome = "annual-pension-income",
  AdditionalIncomeType = "additional-income-type",
  AdditionalAnnualIncome = "additional-annual-income",
  PensionType = "pension-type",
  HousingStatus = "housing-status",
  HousingRentMonthlyAmount = "housing-rent-monthly-amount",
  HousingLoans = "housing-loans",
  AdditionalLoans = "additional-loans",
  AdditionalIncomeInfo = "additional-income-info",
  AlimonyAnnualAmount = "alimony-annual-amount",
  InvestmentsInfo = "investments-info",
  CompanyResult = "company-result",
  CompanyDepreciationCharge = "company-depreciation-charge",
  CompanyNetResult = "company-net-result",
  CompanyLoanInterest = "company-loan-interest",
  CompanyType = "company-type",
  seniorLoansAccept = "senior-loan-accept",
}

export enum CustomerCaseType {
  Solo = "solo",
  Duo = "duo",
  Company = "company",
}

export enum CaseTypePatrimonial {
  Solo = "solo",
  Patrimonial = "patrimonial",
  CommercialCompany = "commercial-company",
}

export enum LoanPurpose {
  RealEstate = "real-estate",
  Consumer = "consumer",
}
