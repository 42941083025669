import sodium from "libsodium-wrappers";

export const generateToken = () => {
  return (crypto.randomUUID() + crypto.randomUUID()).replace(/-/g, "");
};

export const hashString = (str: string) => {
  const dataBytes = sodium.from_string(str);

  const hashBuffer = sodium.crypto_generichash(
    sodium.crypto_generichash_BYTES,
    dataBytes,
  );

  const hashedString = sodium.to_base64(hashBuffer);

  return hashedString;
};
